.pricing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  max-width: 100%;
  margin: 0;
  color: var(--pricing-text);

  h1 {
    padding-bottom: 20px;
  }

  h2 {
    font-weight: bold;
    font-size: 24px;
  }

  &__selector {
    display: flex;
    justify-content: center;
    align-items: center;

    &-bar {
      width: 3rem;
      height: 3rem;
      margin: 0 1.2rem;
      padding: 0.1rem;
      cursor: pointer;
      transition-duration: 5000;
    }
  }

  &__cards {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 2rem auto;
    text-align: center;

    &-left,
    &-middle,
    &-right {
      padding: 1.3rem;
      width: 100%;
      background-color: var(--pricing-main3);
      box-shadow: 0px 0px 19px -8px rgba(0, 0, 0, 0.75);
    }

    &-middle {
      padding: 3rem 1.3rem;
      background: linear-gradient(var(--pricing-main), var(--pricing-main2));
      border-radius: 10px;
    }

    &-middle p,
    &-left p,
    &-right p {
      padding: 1rem 0;
    }

    &-left {
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
    }

    &-right {
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }

    hr {
      width: 80%;
      margin: auto;
      opacity: 0.2;
    }
  }

  @media only screen and (max-width: 1020px) {
    &__cards {
      flex-direction: column;

      &-left,
      &-middle,
      &-right {
        margin: 1rem;
        border-radius: 10px;
        width: 80%;
      }
    }
  }

  button {
    width: 85%;
    margin: 1.5rem auto 0.5rem auto;
    padding: 0.8rem;
    cursor: pointer;
    font-size: 0.8rem;
    transition-duration: 3000;
    color: var(--pricing-text);
    background-color: var(--pricing-button);
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
  }

  button:hover {
    color: var(--pricing-text);
    background-color: transparent;
    border: 1px solid var(--pricing-button);
  }

  &__link {
    color: var(--color-blue);
    cursor: pointer;
    text-decoration: underline;
    text-decoration: none;
  }

  &__pipe {
    color: var(--dashboard-value-color);
  }
}
