@import "../../../../../styles/colors.scss";

.extracted-strings {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__download-button {
    outline: none;
    border-radius: 4px;
    color: white;
    background-color: var(--dashboard-table-tabs);
    padding: 5px 6px;
    border: 1px solid var(--border);
    font-family: Nunito, sans-serif;
    font-size: 13px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 183px;
    margin-bottom: 4px;
  }

  &__rows {
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-height: 297px;
    overflow: auto;

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 40px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba($color: #000000, $alpha: 0.5);
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--header-search-text-color);
      border-radius: 10px;
    }
  }

  &__row {
    font-size: 12px;
    color: white;
    padding: 6px 2px;
    border-bottom: 1px solid var(--dashboard-table-row-border);
    overflow-wrap: break-word;

    @media screen and (max-width: 1600px) {
      font-size: 11px;
    }

    @media screen and (max-width: 1250px) {
      font-size: 12px;
    }
  }

  &__no-data {
    font-size: 12px;
    color: white;
    padding: 6px 2px;
  }
}

.static-findings {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__filter {
    padding: 2px;
  }

  &__results {
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-height: 358px;
    overflow: auto;

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 40px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba($color: #000000, $alpha: 0.5);
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--header-search-text-color);
      border-radius: 10px;
    }
  }

  &__center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__finding {
    padding: 6px 2px;
    border-bottom: 1px solid var(--dashboard-table-row-border);

    h5 {
      font-size: 13px;
      color: var(--analysis-reports-color);
    }

    &__container {
      padding: 0 16px;
      position: relative;

      .bullet-point {
        position: absolute;
        color: var(--analysis-reports-color);
        left: 1px;
        top: 2px;
        line-height: 1;
      }

      p {
        color: white;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          color: var(--analysis-reports-color);
        }
      }
    }
  }

  &__no-data {
    font-size: 12px;
    color: white;
  }
}

.attack-map {
  &__info-container {
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: var(--analysis-report-modal-background);
    padding: 6px 12px;
    border-radius: 4px;
  }

  &__info {
    font-size: 14px;
    color: white;
  }

  &__filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 1600px) {
      gap: 10px;
    }
  }

  &__filters {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;

    @media screen and (max-width: 1600px) {
      gap: 10px;
    }
  }

  &__filter {
    display: flex;
    align-items: center;
    gap: 4px;

    &-label {
      color: white;
      font-size: 12px;
    }

    &-input {
      background-color: var(--settings-field-bg);
      border: 1px solid var(--settings-field-border-color);
      border-radius: 4px;
      color: white;
      font-size: 12px;
      padding: 6px;
      width: 130px;
    }
  }
}
