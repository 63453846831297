.analyzed-emails,
.blocked-emails,
.unblocked-emails {
  padding: 20px 40px 100px;

  &__header {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 5fr 2fr;
    margin-bottom: 50px;
    margin-left: 6px;
    margin-right: 6px;

    h1 {
      color: var(--dashboard-value-color);
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
    }
    p {
      color: var(--dashboard-text-color);
      grid-column: 1 / span 1;
      grid-row: 2 / span 1;
      align-self: center;
    }

    &__select {
      grid-column: 2 / span 1;
      grid-row: 2 / span 1;
      justify-self: end;
      align-self: center;
      display: flex;
      align-items: center;
      gap: 6px;
      height: 40px;

      .select {
        text-transform: unset;
      }
    }
  }

  &__table {
    position: relative;
    min-height: 476px;

    th:first-child,
    td:first-child {
      width: 35px;
    }

    td.hidden {
      svg {
        visibility: hidden;
        opacity: 1;
        display: none;
      }
    }

    &__date {
      white-space: nowrap;
      overflow: hidden;
      span {
        color: var(--collapse-content-text-color);
      }
    }

    td .shrink {
      max-height: 1.3em;
      overflow: hidden;
      position: relative;
      color: transparent;

      &::after {
        content: attr(title);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        color: var(--collapse-content-text-color);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        pointer-events: none;
      }
    }

    &__verdict {
      color: black !important;
      position: relative;
      overflow: hidden;
      width: 120px;

      div {
        padding: 5px 0;
        border-radius: 12px;
        width: 90px;
        text-align: center;
      }

      .apt {
        position: relative;
        background-color: #e65555;
        text-transform: capitalize;

        &::after {
          content: "APT";
          position: absolute;
          color: white;
          font-size: 11.5px;
          font-weight: 500;
          right: 4px;
          bottom: -2px;
          line-height: 1;
          z-index: 9999;
        }
      }

      .n-a {
        color: var(--collapse-content-text-color);
      }
      .malicious {
        background-color: #e65555;
        text-transform: capitalize;
      }
      .suspicious {
        background-color: #e69b55;
      }
      .clean {
        background-color: #58e655;
      }
    }

    &__submissions {
      padding: 5px 24px;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &__detail {
      color: white;
      span {
        color: var(--network-blocks-detail-header) !important;
        font-weight: bold;
        margin-right: 4px;
      }
    }

    &__submission {
      display: grid;
      grid-template-columns: 200px 550px 105px 75px 60px;
      grid-template-rows: 1fr;
      column-gap: 10px;
      justify-items: start;
      align-items: center;

      .verdict-container {
        display: flex;
        align-items: center;
        gap: 4px;

        p {
          color: black !important;
        }

        .verdict {
          padding: 5px 0;
          border-radius: 12px;
          width: 90px;
          text-align: center;
          text-transform: capitalize;

          &--apt {
            position: relative;
            background-color: #e65555;

            &::after {
              content: "APT";
              position: absolute;
              color: white;
              font-size: 11.5px;
              font-weight: 500;
              right: 4px;
              bottom: -2px;
              line-height: 1;
              z-index: 9999;
            }
          }
          &--malicious {
            background-color: #e65555;
          }
          &--suspicious {
            background-color: #e69b55;
          }
          &--clean {
            background-color: #58e655;
          }
        }
      }

      p {
        color: var(--network-blocks-detail-text) !important;
      }

      span {
        color: var(--network-blocks-detail-header) !important;
        font-weight: bold;
        margin-right: 4px;
      }

      p:first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
      }

      p:nth-child(2) {
        @media screen and (max-width: 1275px) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 430px;
        }
      }

      p {
        white-space: nowrap;
        overflow: hidden;
      }

      button {
        cursor: pointer;
        font-size: 11px;
        border: none;
        background-color: var(--network-blocks-sbox-button-bg);
        color: var(--network-blocks-sbox-button-text);
        border-radius: 4px;
        height: 28px;

        &:disabled {
          cursor: not-allowed;
          color: var(--network-blocks-sbox-button-disabled-text);
          background-color: var(--network-blocks-sbox-button-disabled-bg);
        }
      }

      @media screen and (max-width: 1275px) {
        grid-template-columns: 200px 440px 105px 75px 60px;
      }
    }
  }
}

.blocked-emails,
.unblocked-emails {
  &__table {
    th:first-child,
    td:first-child {
      width: unset;
    }

    &__email_id,
    &__date,
    &__reason,
    &__action,
    &__release {
      white-space: nowrap;
      overflow: hidden;
    }

    &__action {
      span {
        border-radius: 6px;
        padding: 2px 6px;
      }

      &--waiting {
        background-color: #e69b55;
        color: black !important;
      }
      &--block {
        background-color: #e65555;
        color: black !important;
      }
      &--released {
        background-color: var(--color-blue);
        color: white !important;
      }
    }

    &__release {
      button {
        cursor: pointer;
        font-size: 10.5px !important;
        border: none;
        background-color: var(--attack-map-popup-button);
        color: var(--attack-map-button-text);
        border-radius: 4px;
        padding: 8px;
        margin: 0 !important;
        width: 55px !important;
        height: unset !important;
      }

      button.disabled {
        cursor: default;
        background-color: var(--network-blocks-sbox-button-disabled-bg);
        color: var(--card-background-color);
      }
    }

    &__details {
      padding: 5px 24px;
      color: white;
    }
  }
}

.mgw-dashboard {
  padding: 20px 40px 100px;

  &__header {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 5fr 2fr;
    margin-bottom: 50px;
    margin-left: 6px;
    margin-right: 6px;

    h1 {
      color: var(--dashboard-value-color);
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
    }
    p {
      color: var(--dashboard-text-color);
      grid-column: 1 / span 1;
      grid-row: 2 / span 1;
      align-self: center;
    }

    &__select {
      grid-column: 2 / span 1;
      grid-row: 2 / span 1;
      justify-self: end;
      align-self: center;
      display: flex;
      align-items: center;
      gap: 6px;
      height: 40px;

      .select {
        text-transform: unset;
      }
    }
  }

  &__rows {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__refresh {
    position: absolute;
    top: -30px;
    right: 6px;
    cursor: pointer;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
  }

  &__item {
    background-color: var(--dashboard-card-color);
    border-radius: 8px;
    padding: 15px;
    color: white;

    h2 {
      font-size: 20px;
      font-weight: 500;
      color: var(--dashboard-text-color);
      margin-bottom: 6px;
    }

    h3 {
      margin-top: 16px;
      font-size: 18px;
      color: var(--dashboard-text-color);
    }

    h4 {
      color: white;
      font-size: x-large;
    }
  }

  &__alerts-section {
    background-color: #e65555;
    padding: 15px;
    border-radius: 8px;

    h2 {
      color: black;
      margin-top: -8px;
    }
  }

  &__alert-list {
    margin-top: 10px;
  }

  &__alert-item {
    color: white;
    font-weight: bold;
    margin: 5px 0;
  }

  &__table-container {
    overflow-x: auto;

    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 10px;

      td {
        font-size: 12px;
      }

      th {
        font-size: 14.5px;
        text-align: inherit;
        color: var(--dashboard-text-color);
      }
    }
  }

  &__apt-analysis-status {
    position: relative;

    div {
      position: absolute;
      top: 40px;
      left: 0;
    }

    p {
      margin-left: 8px;

      span {
        color: var(--dashboard-text-color);
      }
    }
  }
}
