.select-box--container {
  height: 32px;
  padding: 0;
  background: var(--settings-field-bg);
  border: 1px solid var(--settings-field-border-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: relative;
  font-family: Nunito, sans-serif;
}

.select-box--box {
  position: relative;
}

.select-box--selected-item {
  width: 100%;
  padding: 4px 12px;
  z-index: 9999;
  font-family: Nunito, sans-serif;

  .placeholder {
    color: var(--settings-header-text);
  }
}

.select {
  padding: 4px 12px;
  background: var(--settings-field-bg);
  color: var(--settings-article-value);
  cursor: pointer;
  text-transform: capitalize;
  font-family: Nunito, sans-serif;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:hover {
    background-color: var(--checkbox-border);
  }
}

.select-box--arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.select-box--options {
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  z-index: 100000;
  border: 1px solid var(--settings-field-border-color);
  border-radius: 4px;
}