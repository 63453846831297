.pricePopup-overlay {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999 !important;
  color: white;
}

.pricePopup-content {
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  position: relative;
  overflow: scroll;

  h1 {
    text-align: center;
  }

  a {
    color: var(--color-blue);
    cursor: pointer;
    text-decoration: underline;
    text-decoration: none;
  }
}

.pricePopup-close {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  background: none !important;
  border: none !important;
  font-size: 20px !important;
  cursor: pointer !important;
  color: white !important;
  width: auto !important;
  height: auto !important;
}

.pricePopup-body {
  margin-top: 20px;
}
