.log-window {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 59px);
  background-color: var(--submit-popup-background);
  padding: 14px;
  font-size: 12px;

  .buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px;
    margin-bottom: 14px;

    .button {
      cursor: pointer;
      border: none;
      background-color: var(--attack-map-popup-button);
      color: var(--attack-map-button-text);
      border-radius: 4px;
      padding: 8px;
      margin: 0;
      height: 30px;
      font-size: 12px;
      line-height: 12px;

      &--faded {
        color: var(--text-color-card);
        border: 1px solid var(--text-color-card);
        background-color: transparent;
      }
      &--highlighted {
        background-color: var(--attack-map-popup-button);
      }
    }
  }

  .filter {
    padding: 9px;
    border: 1px solid var(--border);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-width: 0;

    input {
      border: 1px solid var(--settings-field-border-color);
      background: var(--settings-field-bg);
      color: var(--dashboard-value-color);
      height: 30px;
      border-radius: 4px;
      font-family: Nunito, sans-serif;
      font-size: 12px;
      padding: 0 12px;

      &::placeholder {
        color: var(--dashboard-text-color);
      }
    }
  }

  table {
    width: 100%;
    border-top: 2px solid #2185d0;
    color: white;
    border: 1px solid var(--border);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    table-layout: fixed;
    border-spacing: 0;

    th,
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid var(--border);
      text-align: left;
      padding: 9px;
    }

    th {
      background-color: var(--analysis-reports-table-header);
    }

    td {
      color: var(--text-color-card);

      &:hover {
        overflow: visible !important;
        white-space: normal !important;
        height: auto !important;
        width: auto !important;
        z-index: 5;
        background-color: var(--analysis-reports-table-header);
        word-wrap: break-word !important;
      }
    }
  }

  .pagination {
    margin-top: 14px;
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--text-color-card);

    button {
      background-color: transparent;
      border: none;
      color: var(--text-color-card);
      font-size: 20px;
      cursor: pointer;

      &:disabled {
        color: grey;
      }
    }

    input[type="number"]::-webkit-inner-spin-button,
    .my-number-input::-webkit-outer-spin-button {
      -webkit-appearance: auto;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: number-input;
    }

    input {
      border: 1px solid var(--settings-field-border-color);
      background: var(--settings-field-bg);
      color: var(--dashboard-value-color);
      height: 30px;
      border-radius: 4px;
      font-family: Nunito, sans-serif;
      font-size: 12px;
      padding: 0 12px;
      margin-left: 6px;
      width: 80px;

      &::placeholder {
        color: var(--dashboard-text-color);
      }
    }

    select {
      padding: 0 12px;
      background: var(--settings-field-bg);
      border: 1px solid var(--settings-field-border-color);
      cursor: pointer;
      display: flex;
      align-items: center;
      border-radius: 4px;
      height: 30px;
      font-size: 12px;
      color: white;
    }
  }

  &__center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
}
