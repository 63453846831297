.logs {
  margin: 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 36px;

  &__heading {
    font-size: 24px;
    color: white;
  }

  &__forms {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: var(--dashboard-text-color);
    font-size: 14px;
  }

  &__top-params {
    display: flex;
    align-items: center;
    gap: 24px;

    & > div {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    input[type="number"]::-webkit-inner-spin-button,
    .my-number-input::-webkit-outer-spin-button {
      -webkit-appearance: auto;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: number-input;
    }
  }

  &__form-group {
    display: flex;
    flex-direction: column;
    gap: 6px;

    h5 {
      font-size: 14px;
      font-weight: 700;
      color: white;
    }
  }

  &__input-groups {
    display: flex;
    // align-items: center;
    gap: 18px;

    button {
      cursor: pointer;
      border: none;
      background-color: var(--attack-map-popup-button);
      color: var(--attack-map-button-text);
      border-radius: 4px;
      padding: 8px;
      margin: 0 !important;

      &:disabled {
        cursor: not-allowed;
        color: var(--network-blocks-sbox-button-disabled-text);
        background-color: var(--network-blocks-sbox-button-disabled-bg);
      }
    }
  }

  &__input-group {
    display: flex;
    align-items: center;
    gap: 6px;

    input {
      border: 1px solid var(--settings-field-border-color);
      background: var(--settings-field-bg);
      color: var(--dashboard-value-color);
      height: 32px;
      border-radius: 4px;
      font-family: Nunito, sans-serif;
      font-size: 14px;
      padding: 0 12px;

      &::placeholder {
        color: var(--dashboard-text-color);
      }
    }

    .select {
      text-transform: unset;
    }
  }

  &__max-result {
    border: 1px solid var(--settings-field-border-color);
    background: var(--settings-field-bg);
    width: 78px;
    color: var(--dashboard-value-color);
    height: 32px;
    border-radius: 4px;
    font-family: Nunito, sans-serif;
    text-align: center;
    font-size: 14px;
  }
}
