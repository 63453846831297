.do-cdr {
  position: relative;
  width: 63px;
  margin-left: -3em;

  button {
    font-size: 12px;

    &:disabled {
      cursor: not-allowed;
      color: var(--network-blocks-sbox-button-disabled-text);
      background-color: var(--network-blocks-sbox-button-disabled-bg);
      border-color: var(--network-blocks-sbox-button-disabled-bg);
    }
  }

  .miniMenu {
    min-width: 120px;
    top: calc(100% + 4px);
  }

  .warning {
    p {
      white-space: normal;
      word-break: normal;
      color: var(--text-color-checkbox) !important;
    }

    button {
      height: 40px;
      font-size: 16px;
    }
  }
}
