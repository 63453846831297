.download-message {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 80px !important;

  & p {
    margin-top: 30px;
    font-size: 16px !important;
    color: white !important;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    span {
      color: white;
    }
  }

  &__file-name {
    font-weight: bold;
  }

  &__progress {
    width: 100%;
    height: 20px;
    border-radius: 3px;
    background-color: var(--settings-background-page);
  }
}
